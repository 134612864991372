import API from './api'
import { axiosWithErrorHandler } from './axios'

export const DEPOSIT = async (state: any) => {
    const response = await axiosWithErrorHandler(`POST`, `${API.API_URL}${API.DEPOSIT}`, state)
    return response.data;
};

export const SEAMLESS_DEPOSIT = async (state: any) => {
    const response = await axiosWithErrorHandler(`POST`, `${API.API_URL}${API.SEAMLESS_DEPOSIT}`, state)
    return response.data;
};

export const TRANSFER_DEPOSIT = async (state: any) => {
    const response = await axiosWithErrorHandler(`POST`, `${API.API_URL}${API.TRANSFER_DEPOSIT}`, state)
    return response.data;
};

export const WITHDRAWAL = async (state: any) => {
    const response = await axiosWithErrorHandler(`POST`, `${API.API_URL}${API.WITHDRAWAL}`, state)
    return response.data;
};

export const SEAMLESS_WITHDRAWAL = async (state: any) => {
    const response = await axiosWithErrorHandler(`POST`, `${API.API_URL}${API.SEAMLESS_WITHDRAWAL}`, state)
    return response.data;
};

export const TRANSFER_WITHDRAWAL = async (state: any) => {
    const response = await axiosWithErrorHandler(`POST`, `${API.API_URL}${API.TRANSFER_WITHDRAWAL}`, state)
    return response.data;
};

export const GET_CREDIT_HISTORY = async (id: string, startDate: string, endDate: string) => {
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.CREDIT_HISTORY}?id=${id}&startDate=${startDate}&endDate=${endDate}`)
    return response.data;
};

export const GET_STATEMENT = async (id: string, filterId: string, startDate: string, endDate: string, page: number, limit: number) => {
    let urlFilterId = ""
    if(filterId) {
        urlFilterId = `&username=${filterId}`
    }
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.GET_STATEMENT}/${id}?page=${page}&pageSize=${limit}${urlFilterId}&startDate=${startDate}&endDate=${endDate}`)
    return response.data;
};